<template>
    <v-app>
        <v-app-bar
            id="vAppBar"
            class="border-b"
            color="white"
            elevation="1"
            extension-height="51"
            tile
        >
            <template v-slot:prepend>
                <v-btn
                    v-if="iam"
                    :icon="navigationDrawer ? '$close' : '$menu'"
                    @click="navigationDrawer = !navigationDrawer"
                />
            </template>

            <v-toolbar-title>
                <portal-target name="toolbar-title">
                    {{ title }}
                </portal-target>
            </v-toolbar-title>

            <template v-slot:append>
                <portal-target name="commands">
                    <v-menu
                        v-if="showConnector"
                        transition="slide-y-transition"
                        width="280"
                    >
                        <template v-slot:activator="{ props: menuProps }">
                            <v-btn
                                icon="$dotsVertical"
                                v-bind="menuProps"
                                variant="plain"
                            />
                        </template>

                        <v-list>
                            <connector/>
                        </v-list>
                    </v-menu>
                    <v-btn
                        v-else-if="!hasAssigments"
                        :loading="loading"
                        icon="$refresh"
                        @click="onLoadAssignments"
                    />
                </portal-target>
            </template>

            <template
                v-if="assignmentExpiring"
                v-slot:extension
            >
                <v-alert
                    :text="expirationText"
                    border="bottom"
                    color="orange-darken-2"
                    density="compact"
                    icon="$calendarClock"
                    style="font-size: 0.90em"
                    tile
                    type="warning"
                />
            </template>
        </v-app-bar>

        <v-navigation-drawer
            v-model="navigationDrawer"
            border="0"
            location="top"
            temporary
        >
            <template v-slot:prepend>
                <portal-target
                    name="assignment-info"
                />
            </template>

            <portal-target
                name="related-assignments"
            />

            <template v-slot:append>
                <v-row
                    class="bg-grey-darken-4 pa-4"
                    dense
                    no-gutters
                >
                    <v-col
                        align-self="center"
                        class="grey--text"
                        cols="8"
                        style="font-size: xx-small"
                    >
                        <div class="font-weight-bold text-grey">
                            &copy; <span v-html="copyYear"/> CC BY-NC-ND 4.0 International
                        </div>
                        <div class="font-italic text-grey-darken-1">
                            {{ title }} v.{{ version }} API v.{{ apiVersion }}
                        </div>
                    </v-col>
                    <v-col
                        class="text-right"
                        cols="4"
                    >
                        <connector
                            v-if="!showConnector"
                            dense
                        />
                    </v-col>
                </v-row>
            </template>
        </v-navigation-drawer>

        <v-main class="background">
            <router-view/>
        </v-main>
    </v-app>

    <v-overlay
        :model-value="!appOnline"
        absolute
        class="align-center justify-center"
        opacity="0.8"
        persistent
    >
        <v-icon
            color="white"
            size="200"
        >
            $wifiOff
        </v-icon>
    </v-overlay>
</template>

<script lang="ts" setup>
import { AssignmentsApi } from "@/@api/AssignmentsApi";
import { useAppStore, usePublisherStore, storeToRefs } from "@/stores";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

let copyYear = String(new Date().getFullYear())
if (copyYear !== "2024") {
    copyYear = `2024&minus;${copyYear}`
}
const title = import.meta.env.VITE_NAME
const version = import.meta.env.VERSION

const AppStore = useAppStore()
const {
    setAppOnline,
    setStandalone,
} = AppStore
const {
    apiVersion,
    appOnline,
    appThemeColor,
    standalone,
} = storeToRefs(AppStore)

const PublisherStore = usePublisherStore()
const {setAssignments} = PublisherStore
const {
    assignment,
    assignments,
    iam,
} = storeToRefs(PublisherStore)

const showConnector = computed(() => {
    if (import.meta.env.DEV) {
        return !iam.value
    }

    return ( standalone.value && !iam.value )
})
const hasAssigments = computed(() => assignments.value.length > 0)
const assignmentExpiring = computed(() => assignment.value?.expirationDays !== undefined)
const expirationText = computed(() => {
    if (assignment.value?.expirationDays) {
        const expirationDays = assignment.value.expirationDays

        if (expirationDays > 1) {
            return `Assegnazione in scadenza tra ${expirationDays} giorni`
        } else {
            return "L'assegnazione scade domani"
        }
    }
})
const loading = ref(false)
const navigationDrawer = ref(false)

onMounted(async () => {
    setStandalone(window.matchMedia('(display-mode: standalone)').matches ||
        ( ( window.navigator as any ).standalone === true ))

    window.addEventListener('online', () => setAppOnline(true))
    window.addEventListener('offline', () => setAppOnline(false))
})

onBeforeUnmount(() => {
    window.removeEventListener('online', () => setAppOnline(true))
    window.removeEventListener('offline', () => setAppOnline(false))
})

watch(appThemeColor, (themeColor: string) => {
    const meta = document.querySelector<HTMLMetaElement>('meta[name=theme-color]')

    if (meta) {
        const fromColor = parseInt(meta.content.replace('#', ''), 16)
        const toColor = parseInt(themeColor.replace('#', ''), 16)

        let r1 = fromColor >> 16
        let g1 = fromColor >> 8 & 0xff
        let b1 = fromColor & 0xff

        const r2 = toColor >> 16
        const g2 = toColor >> 8 & 0xff
        const b2 = toColor & 0xff

        const duration = 10
        const steps = 500 / duration

        const rStep = ( r2 - r1 ) / steps
        const gStep = ( g2 - g1 ) / steps
        const bStep = ( b2 - b1 ) / steps

        let currentStep = 0

        const timer = setInterval(() => {
            if (currentStep >= steps) {
                clearInterval(timer)
            } else {
                r1 += rStep
                g1 += gStep
                b1 += bStep

                meta.content = '#' + ( ( 1 << 24 ) + ( Math.round(r1) << 16 ) + ( Math.round(g1) << 8 ) + Math.round(b1) )
                    .toString(16)
                    .slice(1)

                currentStep++
            }
        }, duration)
    }
})

async function onLoadAssignments() {
    loading.value = true

    setAssignments(await AssignmentsApi.list())

    loading.value = false
}
</script>

<style scoped lang="scss">
.background {
    background-color: #fff;
    background-image: url("@/assets/images/235861.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50%;
    transition: background-color 250ms ease-in;
}
</style>
